import React from "react";

export let breakpoint = 'md';

export let PageHeading = ({children}) => {
    return <h1 className={`mt-3 mb-2 mt-${breakpoint}-6 mb-${breakpoint}-5`}>{children}</h1>
}

export let Container = ({children, className = ''}) => {
    return <div className={`container mt-2 mt-${breakpoint}-6 pb-5 mb-4 ${className}`}>
        {children}
    </div>
}

export let SectionWrapper = ({children, className = '', mt = 'lg', mb = 'lg'}) => {
    return <div className={`container my-2 ${resolveMtMargin(mt)} ${resolveMbMargin(mb)} ${className}`}>
        {children}
    </div>
}

let resolveMtMargin = (size) => {
    switch (size) {
        case 'lg':
            return `mt-2 mt-${breakpoint}-6`
    }
}

let resolveMbMargin = (size) => {
    switch (size) {
        case 'lg':
            return `mb-4 mb-${breakpoint}-6`
        case 'md':
            return `mb-3 mb-${breakpoint}-5`
        case 'sm':
            return `mb-2 mb-${breakpoint}-4`
        case 'xs':
            return `mb-1 mb-${breakpoint}-2`
    }
}