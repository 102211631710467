/* eslint-disable */
import React, {useEffect, useReducer, useState} from "react";
import Loading from "@commons/ui/component/Loading";
import {
    doctorPictureAlt,
    glueLanguagesToBadges,
    toDateTime,
    urlToData,
    useQuery,
    useSpecialityFormatter,
    useTimeFormatter
} from "@commons/infra/helpers";
import {Navigate, useNavigate} from "react-router-dom";
import {DoctorApi} from "../../../api/public/DoctorApi";
import {Helmet} from "react-helmet";
import {connect} from "react-redux";
import DoctorsFilter, {ACTION__FILTERS_UPDATED} from "../../component/DoctorsFilter";
import {CommonPatientLinks} from "@commons/infra/links";
import LocalizedLink, {useLocalizedRoute} from "@commons/infra/i18n/LocalizedLink";
import {FormattedMessage, useIntl} from "react-intl";
import {CenteredColumnRow} from "@commons/ui/layout/CenteredColumnLayout";
import {title} from "@commons/shared";
import {LatvianMetaLinksHelmet} from "@commons/infra/i18n/MetaLinksHelmet";
import {SectionWrapper} from "@commons/ui/layout/common";
import {DateTime} from "luxon";
import {PublicLinks} from "../../../../../portal-latvia/src/utility/links";

const DoctorListPage = () => {
    let {formatMessage} = useIntl()
    let navigate = useNavigate()
    let query = useQuery();
    let localizedRoute = useLocalizedRoute()

    let speciality = (query.get("s") || "").toUpperCase()

    let [doctors, setDoctors] = useState([]);
    let [isLoading, setIsLoading] = useState(true);

    let filterReducer = (asd, action) => {
        switch (action.type) {
            case ACTION__FILTERS_UPDATED:
                if (action.speciality) {
                    navigate(`${location.pathname}?s=${action.speciality.toLowerCase()}`, {replace: true});
                } else {
                    if (asd.speciality) {
                        navigate(`${location.pathname}`, {replace: true});
                    }
                }
                return {search: action.search, speciality: action.speciality}
        }
    }
    // noinspection JSCheckFunctionSignatures
    let [filters, dispatcher] = useReducer(filterReducer, {speciality})

    useEffect(() => {
        setIsLoading(true)
        DoctorApi.getDoctors({search: filters.search, speciality: filters.speciality ? [filters.speciality] : []})
            .then(r => r.data.doctors)
            .then(d => setDoctors(d))
            .finally(() => setIsLoading(false));
    }, [filters.search, filters.speciality])

    return <SectionWrapper mt={'lg'} mb={'sm'}>

        {speciality &&
            <Navigate to={localizedRoute.create(PublicLinks.storyblokSpeciality2(speciality))} replace={true}/>}
        {!speciality &&
            <Navigate to={localizedRoute.create(PublicLinks.storyblokSpecialities(speciality))} replace={true}/>}

        {false && <CenteredColumnRow>

            <LatvianMetaLinksHelmet/>
            <Helmet>
                <title>{title(formatMessage({id: 'pages.doctors.page-title'}))}</title>
                <meta name="description" content={formatMessage({id: 'pages.doctors.meta.description'})}/>
            </Helmet>

            <h1 className={"page-heading"}>
                <FormattedMessage id={"pages.doctors.heading"}/>
            </h1>

            <DoctorsFilter defaultSpeciality={speciality} dispatcher={dispatcher}/>

            <div className={"py-4 py-md-4"}>

                {isLoading && <div className={"text-center mt-3"}><Loading/></div>}

                {!isLoading && doctors.length === 0 && <>
                    <h3 className={"mt-4"}>
                        <FormattedMessage id={"pages.doctors.nothings-found"}/>
                    </h3>
                </>}

                {!isLoading && doctors.length > 0 && <>
                    {doctors.map(doctor => <DoctorCardV2
                        doctor={doctor}
                        link={CommonPatientLinks.doctor.doctor(doctor.slug)}
                    />)}
                </>}
            </div>
        </CenteredColumnRow>}
    </SectionWrapper>
};

export let DoctorCardV2 = ({doctor, rowBreakpoint = 'lg'}) => {
    let timeFormatter = useTimeFormatter();
    let specialityFormatter = useSpecialityFormatter();

    let formattedSpecialities = specialityFormatter.formatSpecialities(doctor.specialities || doctor.speciality);

    return <>
        <LocalizedLink to={CommonPatientLinks.doctor.doctor(doctor.slug)} class={"no-hover-decoration"}>
            <div className={"card card-hover card-lifted shadow border-0 mb-3 h-100"}>
                <div className={`card-body ${rowBreakpoint && 'ps-lg-3 pe-lg-5 py-lg-3'}`}>
                    <div
                        className={`doctor-list d-flex flex-column ${rowBreakpoint && `flex-${rowBreakpoint}-row gap-${rowBreakpoint}-3`} justify-content-between gap-3`}>
                        <div
                            className={`d-flex flex-row justify-content-between ${rowBreakpoint && `gap-${rowBreakpoint}-3`}`}>
                            <div
                                className={`d-flex flex-column order-0 ${rowBreakpoint && `order-${rowBreakpoint}-1`}`}>
                                <h5 className={"mb-0 "}>
                                    {doctor.fullName}
                                </h5>
                                <span className={"fs-sm mb-1"}>
                                    {formattedSpecialities}
                                </span>
                                <div className={"d-block"}>
                                    {glueLanguagesToBadges(doctor.languages)}
                                </div>
                            </div>

                            <DoctorThumbnail
                                className={`ms-2 order-1 order-${rowBreakpoint}-0`}
                                imgSrc={urlToData(doctor.thumbnail)}
                                alt={doctorPictureAlt(name, formattedSpecialities)}/>
                        </div>
                        <div
                            className={`d-flex flex-row justify-content-between ${rowBreakpoint && `gap-${rowBreakpoint}-5`} fl`}>

                            <div className={`order-0 ${rowBreakpoint && `order-${rowBreakpoint}-1`} last-col`}
                                 style={{flex: '1'}}>
                                <h6 className={"fs-sm mb-0"}>Tuvākais laiks</h6>
                                {doctor.nearestAppointment.id ? <>
                                    {timeFormatter.formatShortDateTime(doctor.nearestAppointment.from)}<br/>
                                    <div
                                        className={`badge ${isNearestAppointmentTimeCloseEnough(doctor.nearestAppointment.from) ? 'bg-success-subtle text-success' : 'bg-secondary'} fs-xs mt-1`}>
                                        {timeFormatter.formatTimeTo(doctor.nearestAppointment.from)}
                                    </div>
                                </> : <>
                                    {!doctor.settings.appointmentRequest.enabled && <>&ndash;</>}
                                    {doctor.settings.appointmentRequest.enabled && <>
                                        <span className={`d-none ${rowBreakpoint && `d-${rowBreakpoint}-inline`}`}>
                                        <LocalizedLink to={CommonPatientLinks.doctor.requestAppointment(doctor.slug)}>
                                            <FormattedMessage id={"pages.doctors.request-appointment"}/>
                                        </LocalizedLink>
                                        </span>

                                        <span className={`d-inline ${rowBreakpoint && `d-${rowBreakpoint}-none`}`}>
                                            <FormattedMessage id={"pages.doctors.request-appointment"}/>
                                        </span>
                                    </>}
                                </>}
                            </div>

                            <div className={`order-1 ${rowBreakpoint && `order-${rowBreakpoint}-0`}`}
                                 style={{flex: '0.8'}}>
                                <h6 className={"fs-sm mb-0"}>Cena</h6>
                                <span
                                    className={"text-nowrap"}>{doctor.settings.appointment.price}&euro;  / {doctor.settings.appointment.duration} min</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LocalizedLink>
    </>
}

export let isNearestAppointmentTimeCloseEnough = (timeFrom) => toDateTime(timeFrom).diff(DateTime.now(), 'days')['days'] <= 3

const mapStateToProps = (state) => {
    return {
        authentication: state.authentication
    }
};

export let DoctorThumbnail = ({className = "", size = 75, alt, imgSrc}) => {
    return <div className={"rounded-circle doctor-thumbnail " + className}
                style={{width: size, height: size, backgroundImage: `url("${imgSrc}")`, aspectRatio: 1 / 1}}
                title={alt}/>
}

export default connect(mapStateToProps)(DoctorListPage);
