import React, {useEffect, useState} from 'react';
import {IntlProvider} from 'react-intl';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';

import {useQuery} from "../helpers";
import {LoadingOverlay} from "@commons/uinew/component/loading/LoadingOverlay";

export let LANGUAGE_CODE_LATVIAN = 'lv'
export let LANGUAGE_CODE_ENGLISH = 'en'
export let LANGUAGE_CODE_HINDI_INDIA = 'hi'
export let LANGUAGE_CODE_GERMAN = 'de'
export let LANGUAGE_CODE_RUSSIAN = 'ru'
export let LANGUAGE_CODE_FRENCH = 'fr'
export let LANGUAGE_CODE_POLISH = 'pl'
export let LANGUAGE_CODE_SPANISH = 'es'
let DEFAULT_LANGUAGE = LANGUAGE_CODE_LATVIAN

export const I18nContext = React.createContext({});

export const LocalizedRouter = ({
                                    children,
                                    RouterComponent,
                                    languages = [LANGUAGE_CODE_LATVIAN, LANGUAGE_CODE_RUSSIAN, LANGUAGE_CODE_ENGLISH],
                                    defaultLanguage = DEFAULT_LANGUAGE,
                                    useEmptyLanguageNavigator = false
                                }) => {
    return <RouterComponent>
        {useEmptyLanguageNavigator && <EmptyLanguageNavigator languages={languages} defaultLanguage={defaultLanguage}/>}
        <Routes>
            {languages.includes(LANGUAGE_CODE_LATVIAN) && <Route path={'/*'}
                                                                 element={<IntlChildrenWrapper content={children}
                                                                                               defaultLanguage={defaultLanguage}
                                                                                               language={LANGUAGE_CODE_LATVIAN}/>}/>}
            {languages.includes(LANGUAGE_CODE_RUSSIAN) && <Route path={LANGUAGE_CODE_RUSSIAN + '/*'}
                                                                 element={<IntlChildrenWrapper content={children}
                                                                                               defaultLanguage={defaultLanguage}
                                                                                               language={LANGUAGE_CODE_RUSSIAN}/>}/>}
            {languages.includes(LANGUAGE_CODE_ENGLISH) && <Route path={LANGUAGE_CODE_ENGLISH + '/*'}
                                                                 element={<IntlChildrenWrapper content={children}
                                                                                               defaultLanguage={defaultLanguage}
                                                                                               language={LANGUAGE_CODE_ENGLISH}/>}/>}
            {languages.includes(LANGUAGE_CODE_HINDI_INDIA) && <Route path={LANGUAGE_CODE_HINDI_INDIA + '/*'}
                                                                     element={<IntlChildrenWrapper content={children}
                                                                                                   defaultLanguage={defaultLanguage}
                                                                                                   language={LANGUAGE_CODE_HINDI_INDIA}/>}/>}
        </Routes>
    </RouterComponent>
}

let EmptyLanguageNavigator = ({languages, defaultLanguage}) => {
    let location = useLocation()
    let {pathname, search, hash} = location;
    let matches = languages.find(language => pathname.startsWith(`/${language}`))
    if (!matches) {
        let previousPath = pathname + search + hash
        if (previousPath === "/") {
            previousPath = "";
        }
        return <Navigate replace to={`/${defaultLanguage}${previousPath}`}/>;
    }
}

let IntlChildrenWrapper = ({content, language, defaultLanguage}) => {
    let [messages, setMessages] = useState()
    let [isLoading, setIsLoading] = useState(true)
    let debugMessages = useQuery().has('debugMessages');

    useEffect(() => {
        setIsLoading(true)
        Promise.all([import(`./translations/${defaultLanguage}.json`), import(`./translations/${language}.json`), import(`./translations/routes/${language}.json`)]).then(([defaultMessages, messages, routes]) => {
            if (debugMessages) {
                setMessages(routes);
            } else {
                setMessages({...defaultMessages, ...messages, ...routes})
            }
            setIsLoading(false)
        })
    }, [language, debugMessages])

    return <>
        {isLoading && <LoadingOverlay/>}
        {!isLoading && <I18nContext.Provider value={{language}}>
            <IntlProvider locale={language} messages={messages}>
                {content}
            </IntlProvider>
        </I18nContext.Provider>}
    </>
}
