import {DateTime} from "luxon";
import {
    AUTHORITY_ADMIN,
    AUTHORITY_DOCTOR,
    AUTHORITY_PATIENT,
    AUTHORITY_SO_PATIENT,
    BOOKING_STATUS_CANCELLED,
    DEFAULT_DOCTOR_PROFILE_PICTURE_PATH,
    PATIENT_INVITATION_STATUS__ACCEPTED,
    PATIENT_INVITATION_STATUS__ACTIVE,
    PATIENT_INVITATION_STATUS__EXPIRED,
    PATIENT_INVITATION_STATUS__PENDING_ACTIVATION,
    PATIENT_TYPE__INPATIENT,
    PATIENT_TYPE__OUTPATIENT,
    REGIME__FREE,
    REGIME__HOME,
    REGIME__HOSPITAL
} from "./consts";
import {useLocation} from "react-router-dom";
import {toast} from "react-toastify";
import {
    DOW_FRIDAY,
    DOW_MONDAY,
    DOW_SATURDAY,
    DOW_SUNDAY,
    DOW_THURSDAY,
    DOW_TUESDAY,
    DOW_WEDNESDAY
} from "./component/Days";
import React, {useMemo} from "react";
import {LANGUAGE_CODE_ENGLISH, LANGUAGE_CODE_LATVIAN, LANGUAGE_CODE_RUSSIAN} from "./i18n/LocalizedRouter";
import {localStorageWrapper} from "./localstorage";
import {useIntl} from "react-intl";
import {ErrorToast, InfoToast} from "./component/Toast";
import {store} from "../shared";
import {CommonLinks} from "./CommonLinks";
import {LodashFillIns} from "@commons/lodash";

export const urlToData = (relativePath) => window.CONFIG.DATA_BASE_URL + relativePath;
export let getPrivacyPolicyUrl = (lang) => {
    if (!['lv', 'en'].includes(lang)) {
        lang = 'lv'
    }
    if (lang === 'lv') {
        return urlToData(`documents/lv/Priva%CC%84tuma-politika-240926.pdf`);
    }
    return urlToData(`documents/en/Privacy-policy.pdf`);
}

export let termsAndConditionsUrl = () => urlToData('documents/lv/Platformas-lietos%CC%8Canas-noteikumi_v4.1-240620.pdf');
export let anesthesiologyConsentFormUrl = () => urlToData('documents/lv/Platformas-lietos%CC%8Canas-noteikumi-2405.pdf');

export let getSoPrivacyPolicyUrl = () => {
    return urlToData(`documents/so/Privacy-Policy.pdf`);
}
export let soTermsAndConditionsUrl = () => urlToData('documents/so/Terms-and-Conditions.pdf');

/**
 * Doctor documents
 */
export let higienasNoteikumi = () => urlToData("documents/lv/doctor/Higie%CC%84nas-noteikumi-Medon-SIA-persona%CC%84lam.pdf")
export let pretepidemiskaRezimaPlans = () => urlToData("documents/lv/doctor/Higie%CC%84nas-un-pretepide%CC%84miska%CC%84-rez%CC%8Ci%CC%84ma-pla%CC%84ns.pdf")
export let neatliekamasPalidzibasSniegsana = () => urlToData("documents/lv/doctor/Neatliekama%CC%84s-medici%CC%84niska%CC%84s-pali%CC%84dzi%CC%84bas-sniegs%CC%8Cana.pdf")

export let LOGGED_OUT_EVENT_KEY = "logged_out";

let languageCodeToName = {
    'LV': "latviešu",
    'RU': "krievu",
    'EN': "angļu"
}
export let getLanguageNameByCode = (code) => languageCodeToName[code.toUpperCase()];

export let urlToDoctorProfilePhoto = (relativeUrl) => relativeUrl ? urlToData(relativeUrl) : DEFAULT_DOCTOR_PROFILE_PICTURE_PATH;

export let isLoggedInAsPatient = () => isLoggedInAs(AUTHORITY_PATIENT);
export let isLoggedInAsSoPatient = () => isLoggedInAs(AUTHORITY_SO_PATIENT);
export let isLoggedInAsDoctor = () => isLoggedInAs(AUTHORITY_DOCTOR);
export let isLoggedInAsAdmin = () => isLoggedInAs(AUTHORITY_ADMIN);

export let isLoggedIn = () => store.getState().authentication.isAuthenticated;

function isLoggedInAs(authority) {
    let authentication = store.getState().authentication;
    return authentication.isAuthenticated && authentication.authorities.includes(authority);
}

export let setLoggedOutEvent = () => {
    localStorageWrapper.set(LOGGED_OUT_EVENT_KEY, true)
}

export let getLoggedOutEventAndDelete = () => {
    let isLoggedOutExist = localStorageWrapper.get(LOGGED_OUT_EVENT_KEY);
    localStorageWrapper.remove(LOGGED_OUT_EVENT_KEY);
    return isLoggedOutExist;
}

export let defaultTimeZone = 'local';
// TODO toHook + setLocale
export const toDateTime = (stringOrDateTime) => {
    if (typeof (stringOrDateTime) === 'string') {
        return DateTime
            .fromISO(stringOrDateTime, {zone: 'utc'}) // all backend times/dates are expected to be in UTC (except Schedules)
            .setZone(defaultTimeZone);
    } else {
        return stringOrDateTime;
    }
}

export const isAfter = (dt1, dt2) => toDateTime(dt1) > toDateTime(dt2)
export const isBefore = (dt1, dt2) => toDateTime(dt1) < toDateTime(dt2)
export const parseMinutes = (minutes) => ({
    minutes: minutes % 60,
    hours: Math.floor(minutes / 60)
})

export const HAPPENING_NOW_MINUTES_WINDOW = -30;
export let getTimezone = () => DateTime.now().toFormat('z');
export let isEuropeRigaTimezone = () => getTimezone() === 'Europe/Riga';
export let localTime = () => DateTime.now().toLocaleString(DateTime.TIME_24_SIMPLE);

export let useTimeFormatter = () => {
    let {formatMessage, locale} = useIntl();

    let formatTimeTo = useMemo(() => (dt) => {
        let bookingStart = toDateTime(dt);
        let minutesDiff = bookingStart.diffNow(['minutes']).minutes;
        let fullHoursDifference = Math.floor(bookingStart.diffNow(['hours']).hours);
        let fullDaysDifference = Math.ceil(bookingStart.diffNow(['days']).days);

        switch (true) {
            case minutesDiff < HAPPENING_NOW_MINUTES_WINDOW:
                return formatMessage({id: 'time.time-to.happening-now-skippable'});
            case minutesDiff < 0 && minutesDiff >= HAPPENING_NOW_MINUTES_WINDOW:
                return formatMessage({id: 'time.time-to.happening-now'});
            case minutesDiff < 1:
                return formatMessage({id: 'time.time-to.after-a-minute'});
            case minutesDiff < 60:
                return formatMessage({id: 'time.time-to.after-x-minutes'}, {minutes: Math.floor(minutesDiff)});
            case fullHoursDifference >= 1 && fullHoursDifference < 2:
                return formatMessage({id: 'time.time-to.after-an-hour'});
            case fullHoursDifference <= 24:
                return formatMessage({id: 'time.time-to.after-x-hours'}, {hours: fullHoursDifference});
            case (bookingStart.toRelativeCalendar() === "tomorrow"):
                return formatMessage({id: 'time.time-to.tomorrow'});
            default:
                return formatMessage({id: 'time.time-to.after-x-days'}, {days: fullDaysDifference});
        }
    }, [formatMessage]);

    let formatTimeAgo = useMemo(() => (dt) => {
        let bookingStart = toDateTime(dt);
        let minutesAgo = DateTime.local().diff(bookingStart, ['minutes']).minutes;
        let fullHoursDifference = Math.floor(minutesAgo / 60);
        let fullDaysDifference = Math.ceil(DateTime.local().diff(bookingStart, ['days']).days);

        switch (true) {
            case minutesAgo < 1:
                return formatMessage({id: 'time.time-ago.just-now'});
            case minutesAgo < 2:
                return formatMessage({id: 'time.time-ago.a-minute-ago'});
            case minutesAgo < 60:
                return formatMessage({id: 'time.time-ago.x-minutes-ago'}, {minutes: Math.floor(minutesAgo)});
            case Math.floor(minutesAgo / 60) <= 1:
                return formatMessage({id: 'time.time-ago.an-hour-ago'});
            case fullHoursDifference < 24:
                return formatMessage({id: 'time.time-ago.x-hours-ago'}, {hours: fullHoursDifference});
            case bookingStart.toRelativeCalendar() === "yesterday":
                return formatMessage({id: 'time.time-ago.yesterday'});
            default:
                return formatMessage({id: 'time.time-ago.x-days-ago'}, {days: fullDaysDifference});
        }
    }, [formatMessage]);

    let formatTimeToOrTimeAgo = useMemo(() => (dt) => {
        let minutesAgo = toDateTime(dt).diff(DateTime.local(), ['minutes']).minutes;
        if (minutesAgo > HAPPENING_NOW_MINUTES_WINDOW) {
            return formatTimeTo(dt)
        } else {
            return formatTimeAgo(dt)
        }
    }, [formatTimeTo, formatTimeAgo])

    let formatDate = useMemo(() => (dt) => {
        if (dt == null) return;
        let dateTime = toDateTime(dt).setLocale(locale);

        if (locale === 'lv') {
            return dateTime.toFormat('d. MMM yyyy.');
        } else {
            return dateTime.toLocaleString(DateTime.DATE_FULL)
            // return dateTime.toLocaleString({timeZoneName: 'short', ...DateTime.DATE_FULL})
        }
    }, []);

    let formatTime = useMemo(() => (dt) => {
        if (dt == null) return;
        // Time is written in a 24-hour notation in Europe.
        let dateTime = toDateTime(dt).setLocale(locale);
        if (isEuropeRigaTimezone()) {
            return dateTime.toLocaleString(DateTime.TIME_24_SIMPLE);
        } else {
            return dateTime.toLocaleString({timeZoneName: 'short', ...DateTime.TIME_24_SIMPLE});
        }
    }, []);

    let formatTimeWithoutTz = useMemo(() => (dt) => {
        if (dt == null) return;
        // Time is written in a 24-hour notation in Europe.
        let dateTime = toDateTime(dt).setLocale(locale);
        return dateTime.toLocaleString(DateTime.TIME_24_SIMPLE);
    }, []);

    let formatDateTime = useMemo(() => (dt) => {
        if (dt == null) return;
        return formatMessage({id: 'time.date-time'}, {date: formatDate(dt), time: formatTime(dt)});
    }, [formatMessage, formatDate, formatTime]);

    let formatNumericDateTime = useMemo(() => (dt) => {
        if (dt == null) return;
        return formatMessage({id: 'time.date-time'}, {date: formatNumericDate(dt), time: formatTime(dt)});
    }, [formatMessage, formatDate, formatTime]);

    let formatShortDate = useMemo(() => (dt) => {
        if (dt == null) return;
        return toDateTime(dt).setLocale(locale).toFormat('d. MMM');
    }, []);

    let formatShortDateTime = useMemo(() => (dt) => {
        if (dt == null) return;
        return toDateTime(dt).setLocale(locale).toFormat('d. MMM HH:mm');
    }, []);

    let formatNumericDate = useMemo(() => (dt) => {
        if (dt == null) return;
        return toDateTime(dt).setLocale(locale).toFormat('dd.MM.yyyy.');
    }, [formatMessage, formatDate, formatTime]);

    let formatDurationMinutes = useMemo(() => (from, to) => {
        let diffMinutes = toDateTime(to).diff(toDateTime(from), ['minutes']).minutes
        return formatMessage({id: 'time.duration-minutes'}, {minutes: diffMinutes});
    }, [formatMessage]);

    return useMemo(() => ({
        formatTimeTo,
        formatTimeAgo,
        formatTimeToOrTimeAgo,
        formatDateTime,
        formatNumericDateTime,
        formatDate,
        formatShortDate,
        formatShortDateTime,
        formatTime,
        formatTimeWithoutTz,
        formatDurationMinutes
    }), [
        formatTimeTo,
        formatTimeAgo,
        formatTimeToOrTimeAgo,
        formatDateTime,
        formatDate,
        formatShortDate,
        formatShortDateTime,
        formatTime,
        formatTimeWithoutTz,
        formatDurationMinutes
    ])
}

export let formatHourMinutesDuration = (minutes) => {
    let hourMinutes = minutes % 60;
    let hours = Math.floor(minutes / 60)
    if (hours > 0) {
        return `${hours} st. ${hourMinutes} min.`
    } else {
        return `${hourMinutes} min.`
    }
}

export const isInPast = (dt) => {
    return toDateTime(dt).diff(DateTime.local(), ['minutes']).minutes < 1
}

export let convertWhitespaceToNull = (str) => (str === null || str.match(/^ *$/) !== null) ? null : str;

export let useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

export let useCurrentLocation = () => {
    let location = useLocation();

    return {
        routerLocation: window.CONFIG.FRONTEND_BASE_URL + location.pathname
    }
}

export let openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

export let backendUrl = (path) => window.CONFIG.BACKEND_BASE_URL + path;

export let sleep = async (ms) => await new Promise(r => setTimeout(r, ms))


export let hasSelection = () => {
    return getSelection() !== "";
}

export let getSelection = () => {
    if (window.getSelection) {
        return window.getSelection().toString();
    } else if (document.getSelection) {
        return document.getSelection().toString();
    } else {
        let selection = document.selection && document.selection.createRange();
        if (selection.text) {
            return selection.text.toString();
        }
        return "";
    }
}

export let doctorPictureAlt = (fullName, formattedSpecialities) => {
    return `${fullName} - ${uncapitalize(formattedSpecialities)}`
}

export let useSpecialityFormatter = () => {
    let {formatMessage} = useIntl();
    let formatSpeciality = useMemo(() => (specialityCode) => {
        let messageId = `speciality.classificator.${specialityCode}`;
        return formatMessage({id: messageId})
    }, [formatMessage]);

    return useMemo(() => ({
        formatSpeciality,
        formatSpecialities: (specialityCodes) => {
            return specialityCodes
                .map(s => formatSpeciality(s))
                .join(", ");
        }
    }), [formatSpeciality])
}

export let useDomainTranslations = () => {
    let {formatMessage} = useIntl()
    return useMemo(() => ({
        bookingStatus: (str) => formatMessage({id: `booking-status.${str.toLowerCase()}`}),
        documentType: (documentType) => formatMessage({id: `document-type.${documentType.toLowerCase()}`}),
    }));
}

export let glueLanguages = (languages) => {
    return languages.map(l => getLanguageNameByCode(l)).join(', ')
}

export let glueLanguagesToBadges = (languages) => {
    return sortLanguages(languages)
        .map(l => <div className="badge border fs-xs text-muted me-1">{l}</div>)
}

/**
 * LV should come first
 */
export let sortLanguages = (languages) => {
    let languagesCopy = [...languages]
    let result = []
    if (languagesCopy.includes(LANGUAGE_CODE_LATVIAN.toUpperCase())) {
        result.push(LANGUAGE_CODE_LATVIAN.toUpperCase())
        languagesCopy = LodashFillIns.without(languagesCopy, LANGUAGE_CODE_LATVIAN.toUpperCase())
    }
    if (languagesCopy.includes(LANGUAGE_CODE_RUSSIAN.toUpperCase())) {
        result.push(LANGUAGE_CODE_RUSSIAN.toUpperCase())
        languagesCopy = LodashFillIns.without(languagesCopy, LANGUAGE_CODE_RUSSIAN.toUpperCase())
    }
    if (languagesCopy.includes(LANGUAGE_CODE_ENGLISH.toUpperCase())) {
        result.push(LANGUAGE_CODE_ENGLISH.toUpperCase())
        languagesCopy = LodashFillIns.without(languagesCopy, LANGUAGE_CODE_ENGLISH.toUpperCase())
    }
    languagesCopy.forEach(language => result.push(language))
    return result
}

export let commaGlue = (source, transformation) => {
    return source.map(i => transformation(i)).join(', ')
}

export let capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

export let uncapitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toLowerCase() + s.slice(1)
}

export let unexpectedErrorOccurred = (msg) => {
    toast(<ErrorToast text={msg}/>, {autoClose: false})
}

export let loggedOut = (msg) => {
    if (!msg) msg = "Tava sesija ir beigusies, lai turpinātu, lūdzu, ieej savā Medon profilā atkārtoti"
    toast(<InfoToast text={msg}/>, {autoClose: false, toastId: 'logged-out'})
}

export let translatePatientInvitationStatus = (str) => {
    switch (str) {
        case PATIENT_INVITATION_STATUS__PENDING_ACTIVATION:
            return "Pending activation"
        case PATIENT_INVITATION_STATUS__ACTIVE:
            return "Gaida apstiprinājumu"
        case PATIENT_INVITATION_STATUS__EXPIRED:
            return "Expired"
        case PATIENT_INVITATION_STATUS__ACCEPTED:
            return "Accepted"
        default:
            return str
    }
}

export let translateRegime = (str) => {
    switch (str) {
        case REGIME__HOME:
            return "Mājas režīms"

        case REGIME__HOSPITAL:
            return "Ārstēšanās stacionārā"

        case REGIME__FREE:
            return "Brīvais režīms"

        default:
            return ""
    }
}

export let translatePatientType = (str) => {
    switch (str) {
        case PATIENT_TYPE__INPATIENT:
            return "Ambulatorais pacients"

        case PATIENT_TYPE__OUTPATIENT:
            return "Stacionārais pacients"

        default:
            return ""
    }
}

export let translateLanguage = (code) => {
    switch (code.toUpperCase()) {
        case 'EN':
            return 'English'
        case 'LV':
            return 'Latviešu'
        case 'RU':
            return 'Русский'
        case 'DE':
            return 'Deutsch'
        case 'FR':
            return 'Français'
        default:
            return ''
    }
}

export let uuidv4 = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        // eslint-disable-next-line
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

export let translateDayOfTheWeek = (constant) => {
    switch (constant) {
        case DOW_MONDAY:
            return 'Pirmdiena';
        case DOW_TUESDAY:
            return 'Otrdiena';
        case DOW_WEDNESDAY:
            return 'Trešdiena';
        case DOW_THURSDAY:
            return 'Ceturtdiena';
        case DOW_FRIDAY:
            return 'Piektdiena';
        case DOW_SATURDAY:
            return 'Sestdiena';
        case DOW_SUNDAY:
            return 'Svētdiena';
        default:
            throw new Error(`Cannot translate ${constant}`)
    }
}

export let glueDaysOfTheWeek = (dows) => {
    return dows.map(dow => translateDayOfTheWeek(dow)).join(', ')
}

export let offsetTimeWithZone = (dt) => ({
    time: dt.toFormat("HH:mmZZ"),
    zone: dt.toFormat("z")
})

export const isCancelledBooking = (status) => {
    return status === BOOKING_STATUS_CANCELLED
}

export let handleNotFound = (e, navigate) => {
    if (e.response.status === 404) {
        navigate(CommonLinks.notFound(), {replace: true});
    }
}

/**
 * GA Helpers
 */
export let registerGaEvent = (event, id, ttl_millis) => {
    if (!window.dataLayer) {
        // this should be done beforehand:
        // TagManager.initialize({
        //     gtmId: '...',
        // })
        console.log('GTM is not initialized, skipping GA event registration');
        return;
    }

    if (!ttl_millis) {
        ttl_millis = 1_000 * 60 * 60 * 24;
    }

    console.log(`Registering GA event "${id}" for ${ttl_millis}`, event);
    // try {
    //     if (id) {
    //         let ls_key = `ga_event__${event.event ?? ''}__${id}`;
    //         if (localStorageWrapper.get(ls_key)) {
    //             return;
    //         }
    //         localStorageWrapper.set(ls_key, '1', ttl_millis)
    //     }
    // } catch (e) {
    //     // Errors at deduplication must not affect event tracking
    //     console.error(e);
    // }

    window.dataLayer.push(event);
}

/**
 * Meta helpers
 * https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
 */
export let registerFacebookEvent = (event, data, eventId) => {
    let eventOptions = {eventID: eventId, test_event_code: window.CONFIG.FACEBOOK_PIXEL.TEST_EVENT_CODE};
    if (window.CONFIG.DEBUG) {
        console.log('Meta Pixel: tracking standard event', event, data, eventOptions);
    }
    window.fbq && window.fbq('track', event, data, eventOptions);
}

export let registerCustomFacebookEvent = (event, data, eventId) => {
    let eventOptions = {
        eventID: eventId,
        test_event_code: window.CONFIG.FACEBOOK_PIXEL.TEST_EVENT_CODE
    };
    if (window.CONFIG.DEBUG) {
        console.log('Meta Pixel: tracking custom event', event, data, eventOptions);
    }
    window.fbq && window.fbq('trackCustom', event, data, eventOptions);
}

/**
 * Converts to GA datetime format
 * https://developers.google.com/analytics/devguides/reporting/data/v1/api-schema#:~:text=The%20date%20of%20the%20event%2C%20formatted%20as%20YYYYMMDD.&text=The%20combined%20values%20of%20date%20and%20hour%20formatted%20as%20YYYYMMDDHH.&text=The%20combined%20values%20of%20date%2C%20hour,and%20minute%20formatted%20as%20YYYYMMDDHHMM.&text=The%20day%20of%20the%20month,number%20from%2001%20to%2031.
 * @param dt
 * @returns {string}
 */
export let gaFormatDateTime = (dt) => toDateTime(dt).toFormat("yyyyLLddHHmm");

/**
 * Replaces garumzīmes and others special symbols with a-z letters
 * @param str
 * @returns {*}
 */
export let removeSpecialCharacters = (str) => str.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '');

export let isUuid = (str) =>
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(str);

export let EMPTY_FUNCTION = () => {
};

export let useIntlHelpers = () => {
    let {formatMessage} = useIntl();
    return {
        messageExists: (id) => formatMessage({id: id}) !== id
    }
}

export let delayedPromise = (result, ms) => {
    if (ms == null) ms = 200;
    return new Promise(cb => setTimeout(() => cb(result), ms))
};

export let emptyPromise = (result) => Promise.resolve(result)

export let currentPath = () => {
    let url = new URL(window.location.href)
    return url.href.replace(window.CONFIG.FRONTEND_BASE_URL, "")
}

export let currentPathWithoutParam = (param) => {
    let url = new URL(window.location.href)
    url.searchParams.delete(param)
    return url.href.replace(window.CONFIG.FRONTEND_BASE_URL, "")
}

export let externalLink = (href) => chunks => <a href={href} target="_blank" rel="noreferrer noopener">{chunks}</a>

export let TARGET_BLANK_PROPS = {
    target: "_blank",
    rel: 'noopener noreferrer'
}

export let formatEurPrice = (price) => `${price} EUR`

export let TARGET_BLANK_PROPS_INTERNAL = {
    target: "_blank",
}

export let piExpirationPolicyMessageId = (expirationPolicy) => `common.patient-invitation.expiration-policy.${expirationPolicy}`

export let getKeyByValue = (object, value) => {
    try {
        let result = Object.keys(object).find(key => object[key] === value);
        if (!result) {
            console.error(`Unable to find ${value} in`, object)
        }
        return result
    } catch (e) {
        throw e
    }
}

export let alphabetSort = (a, b) => {
    let a1 = removeSpecialCharacters(a.toLowerCase());
    let b1 = removeSpecialCharacters(b.toLowerCase());
    if (a1 < b1) {
        return -1;
    }
    if (a1 > b1) {
        return 1;
    }
    return 0;
}

export let replaceQueryParams = (newParams, url) => {
    let _url = new URL(url || window.location.href)
    Object.entries(newParams).forEach(([key, value]) => {
        _url.searchParams.set(key, value)
    })

    return _url
        .toString()
        .replace(`${_url.protocol}//`, "")
        .replace(_url.host, "")
}

export let deleteQueryParams = (params, url) => {
    let _url = new URL(url || window.location.href)
    params.forEach((p) => {
        _url.searchParams.delete(p)
    })

    return _url.toString()
}

/**
 *
 * @param arr
 * @param part 1+
 * @param partsTotal
 * @return {*}
 */
export let arrayPart = (arr, part, partsTotal) => {
    let ceil = Math.ceil(arr.length / partsTotal);
    return arr.slice((part - 1) * ceil, part * ceil)
}

/**
 * @param {object} queryParams
 * @return {string} Joined query params string without "?"
 */
export let queryParamsToString = (queryParams) => Object.entries(queryParams)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join("&")

export let useCurrentUrlWithHash = () => {
    let {pathname, search} = useLocation()

    return {
        get: (hash) => pathname + search + '#' + hash
    }
}

/**
 * @param params
 * @return {string}
 */
export let pathUrl = (params) => {
    let dummyBaseUrl = "https://medon.lv"
    let url = new URL(params.path, dummyBaseUrl);

    if (params.queryParams) {
        Object.entries(params.queryParams).forEach(([name, value]) => {
            url.searchParams.append(name, value);
        });
    }

    if (params.anchor) {
        url.hash = params.anchor
    }

    return url.toString().replace(dummyBaseUrl, '')
}

export let smoothScroll = (name) => {
    const element = document.querySelector(`#${name}`);
    const offset = 60; // approx header height, synthetic 6offset
    const elementPosition = element.getBoundingClientRect().top + window.scrollY;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
    });
}