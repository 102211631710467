import React from 'react';
import {useIntl} from "react-intl";
import {Link} from "react-router-dom";
import {LANGUAGE_CODE_LATVIAN} from "@commons/infra/i18n/LocalizedRouter";
import {useLocalizedRoutes} from "@commons/infra/i18n/routes";

let LocalizedLink = ({skipLocalization, ...props}) => {
    let localizedRoute = useLocalizedRoute()
    let newProps = {
        ...props,
        to: localizedRoute.create(props.to, props.queryParams, skipLocalization)
    }

    return <Link {...newProps} />
}

export let useLocalizedRoute = () => {
    let {locale} = useIntl()
    let {unpack} = useLocalizedRoutes()

    return {
        create: (to, queryParams = {}, skipLocalization = false) => {
            let newTo = skipLocalization || locale === LANGUAGE_CODE_LATVIAN ? unpack(to) : maybeAddLanguageCode(unpack(to), locale);
            // its already done in unpack()
            // if (queryParams) {
            //     console.log('asdasd1', to, queryParamsToString(queryParams));
            //     newTo = newTo + '?' + queryParamsToString(queryParams);
            // }
            return newTo
        }
    }
}

let maybeAddLanguageCode = (path, locale) => {
    let isFallbackRoute = path === '*';
    return isFallbackRoute ? path : `/${locale}${path}`;
}

export default LocalizedLink