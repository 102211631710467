/**
 * @param speciality Speciality const, as in classificator
 * @return {string}
 */
export let mapSpecialityToStoryblok = (speciality) => {
    let formatted = speciality.toLowerCase().replaceAll('_', '-')
    return mapSpecialityToStoryblokInner(formatted)
}

let mapSpecialityToStoryblokInner = (formattedSpeciality) => {
    switch (formattedSpeciality) {
        case 'dermatologs-venerologs':
            return 'dermatologs'
        case 'bernu-pneimologs':
            return 'bernu-pneimonologs'
        case 'ginekologs-dzemdibu-specialists':
            return 'ginekologs'
        case 'integrativais-onkologs':
            return 'onkologs'
        case 'kliniskais-un-veselibas-psihologs':
            return 'kliniskais-psihologs'
        case 'otolaringologs':
            return 'otorinolaringologs'
        case 'traumatologs-ortopeds':
            return 'traumotologs-ortopeds'
        case 'gimenes-visparejas-prakses-arsts':
            return 'gimenes-arsts'
        default:
            return formattedSpeciality
    }
}

