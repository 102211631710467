import React from "react";

export let TickedListItem = ({
                                 className = "",
                                 icon = "ai-check",
                                 title = null,
                                 text,
                                 headingClassName = "h6 mb-1",
                                 compensateInnerParagraph = false
                             }) => {
    let classes = title ? "mb-2 pb-2" : "mb-1 pb-0";
    return <div className={`d-flex ${classes} ${className}`}>
        <i className={`${icon} h4 text-primary mb-0`}/>
        <div className="ps-3">
            {title && <h3 className={headingClassName}>
                {title}
            </h3>}
            <p className={`mb-${compensateInnerParagraph ? 'n3' : '0'}`}>
                {text}
            </p>
        </div>
    </div>
}

export let TickedListItemV2 = ({
                                   className = "",
                                   title,
                                   text,
                                   compensateInnerParagraph = false,
                               }) => {
    return <div className={`d-flex mb-2 pb-2 align-items-start ${className}`}>
        <img src={"/img/tick.svg"} className={"mt-2"}/>
        <div className="ps-3 text-start">
            {title && <h3 className={"h5 mb-1"}>
                {title}
            </h3>}
            <div className={`mb-${compensateInnerParagraph ? 'n3' : '0'}`}>
                {text}
            </div>
        </div>
    </div>
}


export let TickedListItemWithoutTitle = ({
                                             children,
                                             className = "",
                                             icon = "ai-check",
                                             iconVerticalAlign = 'start'
                                         }) => {
    return <div className={`ticked-list-item-without-title d-flex align-items-${iconVerticalAlign} ${className}`}>
        <i className={`${icon} h4 text-primary mb-0 text-success`}/>
        <div className="ps-2 py-1">
            <p className="mb-0">
                {children}
            </p>
        </div>
    </div>
}

export let SmallTickedListItemWithoutTitle = ({
                                                  children,
                                                  className = "",
                                                  icon = "ai-check",
                                                  iconVerticalAlign = 'start'
                                              }) => {
    return <div className={`ticked-list-item-without-title d-flex align-items-${iconVerticalAlign} ${className}`}>
        <i className={`${icon} h6 text-primary mb-0 pe-1`} style={{paddingTop: '2px'}}/>
        <div className="ps-2 py-0">
            <p className="mb-1">
                {children}
            </p>
        </div>
    </div>
}

export let TextWithIcon = ({icon, children, className = '', padding = 3}) => {
    return <div className={`d-flex align-items-center ${className}`}>
        {icon}
        <div className={`ps-${padding}`}>
            <p className="mb-0">
                {children}
            </p>
        </div>
    </div>
}