export const BOOKING_STATUS_RESERVED = 'RESERVED';
export const BOOKING_STATUS_OCCUPIED = 'OCCUPIED';
export const BOOKING_STATUS_FINISHED = 'FINISHED';
export const BOOKING_STATUS_CANCELLED = 'CANCELLED';

export const BOOKING_CANCELLATION_INITIATOR_DOCTOR = 'DOCTOR';
export const BOOKING_CANCELLATION_INITIATOR_PATIENT = 'PATIENT';

export const APPOINTMENT_VISIBILITY__PUBLIC = 'PUBLIC';
export const APPOINTMENT_VISIBILITY__PRIVATE = 'PRIVATE';

export const PATIENT_INVITATION_TYPE_REGISTERED_PATIENT = 'REGISTERED_PATIENT';
export const PATIENT_INVITATION_TYPE_UNREGISTERED_PATIENT = 'UNREGISTERED_PATIENT';

export const PATIENT_INVITATION_STATUS__PENDING_ACTIVATION = 'PENDING_ACTIVATION';
export const PATIENT_INVITATION_STATUS__ACTIVE = 'ACTIVE';
export const PATIENT_INVITATION_STATUS__EXPIRED = 'EXPIRED';
export const PATIENT_INVITATION_STATUS__ACCEPTED = 'ACCEPTED';

export const EVENT_TYPE__BOOKING = 'BOOKING';
export const EVENT_TYPE__BOOKING_CANCEL = 'BOOKING_CANCEL';
export const EVENT_TYPE__APPOINTMENT_REQUEST_CREATED = 'APPOINTMENT_REQUEST_CREATED';
export const EVENT_TYPE__APPOINTMENT_REQUEST_ACCEPTED_BY_PATIENT = 'APPOINTMENT_REQUEST_ACCEPTED_BY_PATIENT';
export const EVENT_TYPE__APPOINTMENT_REQUEST_CANCELLED_BY_PATIENT = 'APPOINTMENT_REQUEST_CANCELLED_BY_PATIENT';
export const EVENT_TYPE__APPOINTMENT_REQUEST_EXPIRED = 'APPOINTMENT_REQUEST_EXPIRED';
export const EVENT_TYPE__APPOINTMENT_REQUEST_EXPIRED_BY_PATIENT = 'APPOINTMENT_REQUEST_EXPIRED_BY_PATIENT';

export const AUTHORITY_DOCTOR = 'DOCTOR';
export const AUTHORITY_PATIENT = 'PATIENT';
export const AUTHORITY_SO_PATIENT = 'SO_PATIENT';
export const AUTHORITY_ADMIN = 'ADMIN';
export const AUTHORITY_PARTNER = 'PARTNER';

export const DOCTOR_STATUS_INACTIVE = 'INACTIVE';
export const DOCTOR_STATUS_ACTIVE = 'ACTIVE';

export const ASC = 'ASC';
export const DESC = 'DESC';

export const PAYMENT_STATUS__SUCCESSFUL = 'SUCCESSFUL';
export const PAYMENT_STATUS__FAILED = 'FAILED';

export const AUTHENTICATION_STATUS__INITIATED = 'INITIATED';
export const AUTHENTICATION_STATUS__FAILED = 'FAILED';
export const AUTHENTICATION_STATUS__SUCCESSFUL = 'SUCCESSFUL';

export const AUTHENTICATION_FAILURE_REASON__NO_SUITABLE_ACCOUNT = 'NO_SUITABLE_ACCOUNT';

export const AUTHENTICATION_LINK_TYPE__PATIENT = 'PATIENT';
export const AUTHENTICATION_LINK_TYPE__PATIENT_AUTHENTICATION = 'PATIENT_AUTHENTICATION';
export const AUTHENTICATION_LINK_TYPE__BOOKING = 'BOOKING';
export const AUTHENTICATION_LINK_TYPE__DOCTOR = 'DOCTOR';

export const AUTHENTICATION_METHOD__SWEDBANK = 'swedbank';
export const AUTHENTICATION_METHOD__CITADELE = 'citadele';
export const AUTHENTICATION_METHOD__SMART_ID = 'smart-id';
export const AUTHENTICATION_METHOD__VERIFF = 'veriff';

export const APPOINTMENT_EXPIRATION_POLICY__AFTER_CREATION = 'AFTER_CREATION';
export const APPOINTMENT_EXPIRATION_POLICY__BEFORE_APPOINTMENT_START = 'BEFORE_APPOINTMENT_START';
export const APPOINTMENT_EXPIRATION_POLICY__NONE = 'NONE';

export const AUTHENTICATION_PURPOSE__PATIENT_REGISTRATION = 'PATIENT_REGISTRATION';
export const AUTHENTICATION_PURPOSE__PATIENT_AUTHENTICATION = 'PATIENT_AUTHENTICATION';
export const AUTHENTICATION_PURPOSE__DOCTOR_REGISTRATION = 'DOCTOR_REGISTRATION';
export const AUTHENTICATION_PURPOSE__BOOKING = 'BOOKING';

export const SIGNING_METHOD__PHYSICAL = 'PHYSICAL';
export const SIGNING_METHOD__DIGITAL = 'DIGITAL';

export const PATIENT_TYPE__INPATIENT = 'INPATIENT';
export const PATIENT_TYPE__OUTPATIENT = 'OUTPATIENT';

export const REGIME__HOME = 'HOME';
export const REGIME__HOSPITAL = 'HOSPITAL';
export const REGIME__FREE = 'FREE';

export const COOPERATION_METHOD__EMPLOYMENT = 'EMPLOYMENT';
export const COOPERATION_METHOD__COOPERATION = 'COOPERATION';

export const DOCUMENT_TYPE__PREPAY_INVOICE = 'PREPAY_INVOICE';
export const DOCUMENT_TYPE__FINAL_INVOICE = 'FINAL_INVOICE';
export const DOCUMENT_TYPE__U27 = 'U27';
export const DOCUMENT_TYPE__ANESTHESIOLOGY__PATIENT_CONDITION = 'ANESTHESIOLOGY__PATIENT_CONDITION';
export const DOCUMENT_TYPE__ANESTHESIOLOGY__SIGNED_PATIENT_CONDITION = 'ANESTHESIOLOGY__SIGNED_PATIENT_CONDITION';
export const DOCUMENT_TYPE__ANESTHESIOLOGY__PATIENT_CONSENT = 'ANESTHESIOLOGY__PATIENT_CONSENT';
export const DOCUMENT_TYPE__ANESTHESIOLOGY__SIGNED_PATIENT_CONSENT = 'ANESTHESIOLOGY__SIGNED_PATIENT_CONSENT';
export const DOCUMENT_TYPE__ANESTHESIOLOGY__DOCTOR_DISCHARGE = 'ANESTHESIOLOGY__DOCTOR_DISCHARGE';
export const DOCUMENT_TYPE__ANESTHESIOLOGY__SIGNED_DOCTOR_DISCHARGE = 'ANESTHESIOLOGY__SIGNED_DOCTOR_DISCHARGE';

export const APPOINTMENT_REQUEST_STATUS__NEW = 'NEW';
export const APPOINTMENT_REQUEST_STATUS__SATISFIED = 'SATISFIED';
export const APPOINTMENT_REQUEST_STATUS__REJECTED_BY_DOCTOR = 'REJECTED_BY_DOCTOR';
export const APPOINTMENT_REQUEST_STATUS__CANCELLED_BY_PATIENT = 'CANCELLED_BY_PATIENT';
export const APPOINTMENT_REQUEST_STATUS__EXPIRED = 'EXPIRED';

export const DEFAULT_DOCTOR_PROFILE_PICTURE_PATH = '/img/dashboard/profile-picture-placeholder.png';

export const EMAIL_VERIFICATION_TYPE__MANUAL = 'manual'
export const EMAIL_VERIFICATION_TYPE__OAUTH = 'oauth'

export const ERROR_ALREADY_OCCUPIED = 'ALREADY_OCCUPIED'
export const REGISTRATION_EMAIL_ALREADY_EXISTS = 'REGISTRATION_EMAIL_ALREADY_EXISTS'
export const LOGIN_ERROR__OAUTH_EMAIL_NOT_FOUND = 'OAUTH_EMAIL_NOT_FOUND'
export const LOGIN_ERROR__OAUTH_UNLINKED_PROVIDER = 'OAUTH_UNLINKED_PROVIDER'

export const REGISTRATION_ERROR__OAUTH_EMAIL_ALREADY_REGISTERED_BUT_IS_NOT_LINKED = 'OAUTH_EMAIL_ALREADY_REGISTERED_BUT_IS_NOT_LINKED'

export const SPECIALITY_ANESTHESIOLOGIST = 'ANESTEZIOLOGS_REANIMATOLOGS'
export const SPECIALITY_KARDIOLOGS = 'KARDIOLOGS'
export const SPECIALITY_GIMENES_VISPAREJAS_PRAKSES_ARSTS = 'GIMENES_VISPAREJAS_PRAKSES_ARSTS'
export const SPECIALITY_DERMATOLOGS = 'DERMATOLOGS'
export const SPECIALITY_DERMATOLOGS_VENEROLOGS = 'DERMATOLOGS_VENEROLOGS'
export const SPECIALITY_TRAUMOTOLOGS_ORTOPEDS = 'TRAUMATOLOGS_ORTOPEDS'
export const SPECIALITY_PEDIATRS_MIEGA_SPECIALISTS = 'PEDIATRS_MIEGA_SPECIALISTS'

export const COMMUNICATION_TYPE__PARSIRDI_DIENASGRAMATA_REMINDER = 'PARSIRDI_DIENASGRAMATA_REMINDER'