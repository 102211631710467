import {mobileApplicationCallButtonFeature} from "@commons/infra/mobile";
import {backendUrl} from "@commons/infra/helpers";
import axios from "@commons/infra/http/axios/axios";

export let CommonPublicLinks = {
    contacts: () => ({id: `contacts`}),
    doctors: () => ({id: `storyblok.specialities`}),
    doctor: (doctorId) => ({id: `doctor`, params: {'doctorId': doctorId}}),
    aboutUs: () => ({id: `about`}),
    faq: () => ({id: `faq`}),
    healthIssues: () => ({id: 'health-issues'}),
    healthIssue: (id) => ({id: 'health-issue', params: {localized_healthIssueId: id}}),
    instructions: {
        micAndCamera: () => "/instructions/camera-mic-access",
    }
}

export let CommonPatientLinks = {
    dashboard: () => '/my/dashboard',
    authentication: {
        status: (authenticationId) => `/my/authentication/${authenticationId}/status`
    },
    profile: {
        info: () => '/my/profile/info',
        oauth: () => '/my/profile/oauth',
        surveys: () => '/my/profile/surveys',
        cardioDiary: () => '/my/profile/cardio-diary',
        notifications: () => '/my/profile/notifications',
        termsAndConditions: () => '/my/profile/terms-and-conditions'
    },
    reservation: {
        reservation: (doctorId, appointmentId) => `/doctors/${doctorId}/appointments/${appointmentId}`,
        payment: (doctorId, bookingId) => `/doctors/${doctorId}/bookings/${bookingId}/payment`,
        paymentStatus: (doctorId, bookingId) => `/doctors/${doctorId}/bookings/${bookingId}/payment/status`,
    },
    appointmentRequest: {
        form: (doctorId) => `/doctors/${doctorId}/request-appointment`,
        confirm: (doctorId) => `/doctors/${doctorId}/request-appointment/confirm`,
        summary: (appointmentRequestId) => `/my/appointment-requests/${appointmentRequestId}`,
    },
    document: {
        view: (documentId) => backendUrl(`/api/patient/document/${documentId}`)
    },
    appointment: {
        patientBookingDocument: (bookingId, documentId) => backendUrl(`/api/patient/booking/${bookingId}/document/${documentId}`),
    },
    booking: {
        summary: (bookingId) => `/my/bookings/${bookingId}`,
        patientInfo: (bookingId) => `/my/bookings/${bookingId}/patient-info`,
        authentication: (bookingId) => `/my/bookings/${bookingId}/authentication`,
        call: (bookingId) => mobileApplicationCallButtonFeature().isEnabled() ? CommonPatientLinks.booking.callPlatformSelection(bookingId) : `/my/bookings/${bookingId}/call`,
        callPlatformSelection: (bookingId) => `/my/bookings/${bookingId}/platform-selection`,
        joinCallOnMobile: (bookingId) => `/my/bookings/${bookingId}/platform-selection/mobile-qr`,
        callInternal: (bookingId) => `/my/bookings/${bookingId}/call`,
        list: () => `/my/bookings`
    },
    doctor: {
        requestAppointment: (doctorId) => `/doctors/${doctorId}/request-appointment`,
        doctor: (doctorId) => ({id: `doctor`, params: {'doctorId': doctorId}}),
    }
}

export let OAUTH_PROVIDER__GOOGLE = 'google'
export let OAUTH_PROVIDER__FACEBOOK = 'facebook'

export let OAUTH_ACTION__REGISTER = 'register'
export let OAUTH_ACTION__LOGIN = 'login'
export let OAUTH_ACTION__SO_LOGIN = 'so_login'
export let OAUTH_ACTION__SO_APPOINTMENT_REQUEST_REGISTER = 'so_appointment_request_register'
export let OAUTH_ACTION__SO_BOOK_NOW_REGISTER = 'so_book_now_register'
export let OAUTH_ACTION__SO_USER_ADD_PROVIDER = 'so_user_add_provider'

export let OAuthLinks = {
    google: (action) => OAuthLinks.createLink('google_so', action),
    facebook: (action) => OAuthLinks.createLink('facebook_so', action),
    createLink: (provider, action) => new URL(`/api/oauth2/authorization/${provider.toLowerCase()}?action=${action}`, axios.defaults.baseURL).toString()
}