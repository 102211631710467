import {FormattedMessage} from "react-intl";
import React from "react";
import {TickedListItem, TickedListItemWithoutTitle} from "@commons/ui/component/UnorderedList";
import {TARGET_BLANK_PROPS} from "@commons/infra/helpers";

export let CustomFormattedMessage = ({id, values}) => {
    let values2 = {
        ...{
            br: <br/>,
            span: chunks => <span>{chunks}</span>,
            i: chunks => <i>{chunks}</i>,
            p: chunks => <p>{chunks}</p>,
            strong: chunks => <strong>{chunks}</strong>,
            b: chunks => <strong>{chunks}</strong>,
            semibold: chunks => <strong className={"fw-semibold"}>{chunks}</strong>,
            ul: chunk => <ul className={"mt-2"}>{chunk}</ul>,
            li: chunk => <li>{chunk}</li>,
            item: chunks => <TickedListItem icon="ai-arrow-right" text={chunks}/>,
            checkItem: chunks => <TickedListItem icon="ai-check" text={chunks}/>,
            checkItemWithoutTitle: chunks => <TickedListItemWithoutTitle
                icon="ai-check">{chunks}</TickedListItemWithoutTitle>,
            eveselibaLink: chunk => <a href={"https://eveseliba.gov.lv/"} {...TARGET_BLANK_PROPS}>
                {chunk}
            </a>,
        }, ...values
    };
    return <FormattedMessage
        id={id}
        values={values2}/>
}
